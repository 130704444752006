<template>
    <ModalContent ref="modal" width="640px" :showFooter="false" v-bind="$attrs" v-on="$listeners" name="add-role-and-permission-modal" title="Add Role and Permissions" @closed="closeModal">
        <FormulateForm #default="{ hasErrors }" class="flex flex-col flex-1 overflow-y-auto bg-card-bg">
            <div class="p-6 max-h-full overflow-y-auto flex flex-1 flex-col">
                <FormulateInput class="w-full" label="Role Name" placeholder="Role Name" type="text" validation="required|matches:/^[a-zA-Z0-9\s]+$/|max:24,length" :validation-messages="{ matches: 'only accept numbers and alphabets.' }" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="roles_and_permission.name" />

                <FormulateInput class="w-full" label="Role Description" placeholder="Role Description" type="textarea" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="roles_and_permission.description" />

                <div class="flex flex-col overflow-y-auto flex-1 min-h-16">
                    <div v-if="loadingStates.permission" class="flex justify-center items-center">
                        <Loader />
                    </div>
                    <PermissionsCard @viewPermissionsInfo="$emit('viewPermissionsInfo', $event)" @selectAllPer="getSelect($event)" v-else :Permissions="permissionsList" />
                </div>
            </div>
            <div class="flex justify-between px-6 py-3">
                <Button :disabled="loadingStates.createRole" text="Cancel" type="ghost" @click="closeModal" />
                <Button :loader="loadingStates.createRole" text="Save" type="primary" :disabled="!roles_and_permission.name || !isPermissionsSelected || hasErrors" @click="$emit('handleSubmit', { roles_and_permission })" />
            </div>
        </FormulateForm>
    </ModalContent>
</template>

<script>
import ModalContent from "@shared/modal-content";
import Button from "@shared/components/button";
import PermissionsCard from "./permissionsCard.vue";
import Loader from "@shared/loader";

export default {
    name: "add-roles-and-permission-modal",
    components: {
        ModalContent,
        Button,
        PermissionsCard,
        Loader,
    },
    props: {
        loadingStates: {
            type: Object,
            default: () => {},
        },
        permissionsList: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            roles_and_permission: {
                name: "",
                description: "",
            },
        };
    },
    mounted() {
        this.resetModalData();
        this.closeModal();
    },
    computed: {
        isPermissionsSelected() {
            let selected_per = [];
            this.permissionsList.forEach((el) =>
                el.permissions.forEach((per) => {
                    if (per.selected) {
                        selected_per.push(per);
                    }
                })
            );
            return selected_per.length;
        },
    },
    methods: {
        closeModal() {
            this.$modal.hide("add-role-and-permission-modal");
        },
        showModal() {
            this.resetModalData();
            this.$modal.show("add-role-and-permission-modal");
        },
        resetModalData() {
            this.roles_and_permission = {
                name: "",
                description: "",
            };
        },
        getSelect(event) {
            this.$emit("selectAllPer", event);
        },
    },
};
</script>

<style lang="scss" scoped></style>
